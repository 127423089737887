.home .background {
    background: linear-gradient(var(--background), var(--tint-one));
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: -1;
}

.home h1 {
    font-size: 60px;
    margin-bottom: 30px;
}

.home h1 span {
    display: block;
    width: auto;
    height: auto;
}

.home h1 .two {
    margin-left: 90px;
}

.home h1 .three {
    margin-left: 180px;
}

.home p {
    font-size: 30px;
    width: 800px;
}

.home .gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}

.home .verticalFlexbox {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.home a {
    display: block;
    border-style: solid;
    border-color: var(--shade-two);
    border-radius: 5px;
    background-color: var(--background);
    color: var(--shade-two);
    width: 200px;
    font-size: 30px;
    text-align: center;
    text-decoration: none;
}
