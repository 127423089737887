.diceCalculator form {
    background-color: var(--tint-two);
    border-color: var(--text);
    border: 1px;
    border-style: solid;
    border-radius: 10px;
    padding: 10px;
    gap: 10px;
}

.diceCalculator input[type="submit"] {
    background-color: var(--accent-one);
    border: 1px;
    border-style: solid;
    border-radius: 5px;
    font-weight: bold;
    padding: 5px;
    margin-top: 5px;
}

.diceCalculator .activities {
    display: flex;
    gap: 10px
}

.diceCalculator section {
    background-color: var(--tint-one);
    border-color: var(--text);
    border: 1px;
    border-style: solid;
    border-radius: 10px;
    padding: 10px;
    gap: 10px;
}

.diceCalculator p {
    margin: 0px;
}

.diceCalculator hr {
    height: 0.5px;
    background-color: black;
    margin: 5px 0px;
}

.diceCalculator section {
    position: relative;
}

.diceCalculator .deleteButton {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
}