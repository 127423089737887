* {
    --background: white;
    --text: black;
    --tint-one: #e7dcff;
    --tint-two: #dce3ff;
    --tint-one-shadow: #d7c4ff;
    --tint-two-shadow: #c9d5ff;
    --shade-one: #7113eb;
    --shade-two: #4423ff;
    --accent-one: #84E296;
    --accent-two: #E65768;
    --link: #0036FA;
}

body {
    margin: 0;
    background-color: var(--background);
}

/* defaults */

* {
    padding: 0;
    margin: 0;
    font-family: sans-serif;
    color: var(--text);
    line-height: 1.5em;
}

a {
    color: VAR(--link);
}

ul {
    list-style-position: inside;
}

li {
    margin-left: 1em;
}

/* header */

header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;
    
    height: 40px;
    background: linear-gradient(35deg, VAR(--shade-one) 25%, VAR(--shade-two) 50% 25%, VAR(--shade-one));
    margin: 0px;
    padding: 20px;
}

@media (max-width: 250px){
    header {
        width: 250px;
    }
}

.logo {
    color: var(--background);
    text-decoration: none;
    font-size: xx-large;
}

.links {
    position: absolute;
    right: 50px;
    bottom: 25px;
    display: flex;
    gap: 20px;
}

.links a {
    color: var(--background);
    text-decoration: none;
    font-size: large;
}

header img {
    height: 1.5rem;
    margin-right: 5px;
}

/* main */

main {
    position: absolute;
    padding: 20px;
    padding-top: 100px;
    padding-bottom: 35px;
}

main > * {
    margin-top: 0;
}

/* cards */

.card {
    background-color: var(--tint-one);
    border-style: solid;
    border-radius: 10px;
    border-color: var(--tint-one-shadow);
    border-top-width: 0px;
    border-left-width: 0px;
    padding: 10px;
    width: 200px;
    height: 300px;
    color: black;
    text-decoration: none;
    font-size: large;
}

.card img {
    width: 200px;
    height: 125px;
}

.card h1 {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 130%;
}

/* footer */

footer {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 100;
    height: 20px;
    background: var(--background);
    border-color: var(--shade-two);
    border-style: solid;
    border-width: 2px 2px 0px 0px;
    margin: 0px;
    padding: 0.3em;
    border-top-right-radius: 5px;
}

.icon {
    font-family: sans-serif !important;
}

.skipLink {
    z-index: 200;
    position: fixed;
    background-color: var(--accent-one);
    color: var(--text);
    width: 100vw;
    border-width: 0px 0px 0.2em 0px;
    border-style: solid;
    text-align: center;
    font-size: large;
    padding: 1em 0px;
}

.hideVisual:not(:focus):not(:active) {
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
  }