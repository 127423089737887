.creatureCreator .numberInput {
    display: inline-grid;
    grid-template-columns: auto auto 1fr;
    grid-template-rows: 0.75em 0.75em;
}

.creatureCreator .numberInput input,label {
    grid-row: span 2;
}

.creatureCreator .numberInput label {
    margin-right: 0.3em;
}

.creatureCreator .numberInput button {
    font-size: 0.5em;
}