.creature {
    font-size: small;
}

.creature * {
    margin: 0px;
}

.creature > * {
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 2px;
}

.creature .title {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 1.5em;
    font-stretch: condensed;
}

.creature .tags {
    font-stretch: condensed;
    display: flex;
}

.creature .tags p {
    color: white;
    font-size: 0.7em;
    border-color: orange;
    border-style: solid;
    border-width: 0.1em;
    padding: 0px 0.3em;
    margin: 0.1em;
}

.creature .orange {
    background-color: rgb(145, 99, 14);
}

.creature .green {
    background-color: green;
}

.creature .red {
    background-color: rgb(111, 10, 10);
}

.creature .other {
    border-width: 0px;
}