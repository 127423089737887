.autoForm {
    font-size: small;
}

.autoForm * {
    margin: 0px;
}

.autoForm > * {
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 2px;
}

.autoForm .title {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 1.5em;
    font-stretch: condensed;
}

.autoForm .tags {
    font-stretch: condensed;
    display: flex;
}

.autoForm .tags * {
    color: white;
}

.autoForm .tags button, .autoForm .tags select {
    color: white;
    font-size: 0.7em;
    border-color: orange;
    border-style: solid;
    border-width: 0.1em;
    padding: 0px 0.3em;
    margin: 0.1em;
}

.autoForm .addTag {
    width: 7.5em;
}

.autoForm .orange {
    background-color: rgb(145, 99, 14);
}

.autoForm .green {
    background-color: green;
}

.autoForm .red {
    background-color: rgb(111, 10, 10);
}

.autoForm .other {
    border-width: 0px;
}

.autoForm .skillWrapper {
    display: grid;
    width: fit-content;
    grid-template-columns: auto auto auto;
    column-gap: 5px;
    row-gap: 3px;
    grid-auto-rows: 1fr;
    padding: 0.3em;
}

.autoForm legend {
    font-weight: bold;
}

.autoForm .lore {
    display: flex;
    justify-content: space-between;
}

.autoForm .lore input {
    box-sizing: border-box;
    height: 100%;
    flex-grow: 1;
}